import {
  useState,
  useEffect,
} from 'react'
import Link from 'next/link'
import {
  useRouter,
} from 'next/router'
import {
  createUseStyles,
} from 'react-jss'
import classNames from 'classnames'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Colors from '../utils/Colors'
import Logo from './Logo'
import Button from './Button'
import NavMenuIcon from './icons/NavMenuIcon'

const useHeaderStyles = createUseStyles(theme => ({
  container: {
    zIndex: 100,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    paddingTop: theme.spacing.unit * 1.5,
    paddingBottom: theme.spacing.unit * 1.5,
    backgroundColor: Colors.white,
    color: Colors.primaryCharcoal,
    transition: 'color, background-color .2s linear',
  },
  containerTransparent: {
    backgroundColor: 'transparent',
    color: Colors.white,
    textShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: theme.spacing.unit * 6,
    paddingRight: theme.spacing.unit * 6,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
  },
  logo: {
    height: 44,
    width: 143,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      height: 26,
      width: 80,
    },
  },
  links: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '27px',
    textAlign: 'right',
    marginLeft: theme.spacing.unit * -2,
    marginRight: theme.spacing.unit * -2,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  link: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    cursor: 'pointer',
  },
  linkListItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  linkButton: {
    width: 'auto',
    height: theme.spacing.unit * 5,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    fontSize: 16,
    fontWeight: '500',
    textShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      height: 26,
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      fontSize: 11,
      marginRight: theme.spacing.unit * 2,
    },
  },
  mobileLinks: {
    display: 'none',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  mobileOnly: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  drawer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  }
}))

const PublicHeader = ({ transitionHeight=0 }) => {
  const classes = useHeaderStyles()
  const router = useRouter()

  const [transparent, setTransparent] = useState(transitionHeight > 0)
  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  useEffect(() => {
    if (transitionHeight > 0) {
      const handleScroll = () => {
        setTransparent(window.scrollY <= transitionHeight)
      }

      handleScroll()

      document.addEventListener('scroll', handleScroll)

      return () => {
        document.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const onClickSignup = () => {
    router.push('/signup')
  }

  const onClickToggleMenu = () => {
    setMobileNavOpen(!mobileNavOpen)
  }

  const onClickOpenMenu = () => {
    setMobileNavOpen(true)
  }

  const onClickCloseMenu = () => {
    setMobileNavOpen(false)
  }

  return (
    <div className={classNames(classes.container, {
      [classes.containerTransparent]: transparent,
    })}>
      <div className={classes.content}>
        <Link href="/">
          <img
            src="/rebnise-logo.svg"
            className={classes.logo} />
        </Link>
        <div className={classes.links}>
          <Link href="/shopentry">
            <div className={classes.link}>
              レストラン登録
            </div>
          </Link>
          <a href="/faq">
            <div className={classes.link}>
              よくある質問
            </div>
          </a>
          <Link href="/login">
            <div className={classes.link}>
              ログイン
            </div>
          </Link>
          <Button
            text="アカウント作成"
            className={classes.linkButton}
            onClick={onClickSignup} />
        </div>
        <div className={classNames(classes.mobileLinks)}>
          <Button
            text="アカウント作成"
            className={classes.linkButton}
            onClick={onClickSignup} />
          <NavMenuIcon
            fill={transparent ? '#ffffff' : '#A3A3A3'}
            onClick={onClickToggleMenu} />
        </div>
      </div>
      <SwipeableDrawer
        anchor="top"
        open={mobileNavOpen}
        classes={{
          root: classes.mobileOnly,
        }}
        onOpen={onClickOpenMenu}
        onClose={onClickCloseMenu}>
        <div
          role="presentation"
          onClick={onClickOpenMenu}
          onKeyDown={onClickCloseMenu}>
          <List>
            <Link href="/login">
              <ListItem
                button
                className={classes.linkListItem}>
                <ListItemText
                  primary="ログイン"
                  classes={{
                    root: classes.link,
                  }} />
              </ListItem>
            </Link>
            <Link href="/shopentry">
              <ListItem
                button
                className={classes.linkListItem}>
                <ListItemText
                  primary="レストラン登録"
                  classes={{
                    root: classes.link,
                  }} />
              </ListItem>
            </Link>
            <a href="/faq">
              <ListItem
                button
                className={classes.linkListItem}>
                <ListItemText
                  primary="よくある質問"
                  classes={{
                    root: classes.link,
                  }} />
              </ListItem>
            </a>
          </List>
        </div>
      </SwipeableDrawer>
    </div>
  )
}

export default PublicHeader
